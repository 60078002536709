$epicRed: #ba122b;
$linkedInBlue: #0e76a8;
$gitHubGreen: #6cc644;
$hamiltonBlue: #002f86;
$aitGreen: #6cb33c;

.text-epic-red {
    color: $epicRed !important;
}

.border-epic-red {
    border-color: $epicRed !important;
}

.bg-epic-red {
    background-color: $epicRed !important;
}

.text-linkedIn-blue {
    color: $linkedInBlue !important;
}

.border-linkedIn-blue {
    border-color: $linkedInBlue !important;
}

.bg-linkedIn-blue {
    background-color: $linkedInBlue !important;
}

.text-gitHub-green {
    color: $gitHubGreen !important;
}

.border-gitHub-green {
    border-color: $gitHubGreen !important;
}

.bg-gitHub-green {
    background-color: $gitHubGreen !important;
}

.text-hamilton-blue {
    color: $hamiltonBlue important;
}

.border-hamilton-blue {
    border-color: $hamiltonBlue !important;
}

.bg-hamilton-blue {
    background-color: $hamiltonBlue !important;
}

.text-ait-green {
    color: $aitGreen !important;
}

.border-ait-green {
    border-color: $aitGreen !important;
}

.bg-ait-green {
    background-color: $aitGreen !important;
}
