@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.widget {
    border: none;
    border-left: 0.5rem solid;
    padding: 1em;
    box-shadow: 0px 0px 10px #aaa;

    .card-subtitle {
        margin-bottom: 0.75em;
    }

    .widget-header {
        display: flex;

        .card-title {
            flex-grow: 1;
        }
    }

    .card-footer {
        background-color: inherit;
        padding: 0.5rem 0rem 0rem 0rem;
        border: none;
    }
}