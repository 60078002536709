#brand {
  display: flex;

  .logoContainer {
    width: 52px;
    height: 52px;
  }
}

.projectList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

#contact {
  ul {
    font-size: large;
  }
}

ul.fa-ul .fa-li {
  margin-top: 0.4em;
}

#introduction {
  display: flex;
  align-items: center;
}

#mainPhoto {
  margin-right: 2rem;
}

#description {
  margin-top: 3rem;
  font-size: x-large;
}

section {
  margin-bottom: 4rem;

  > .widget {
    margin-bottom: 2rem;
  }
}

.projectVideo {
  margin-top: 1em;
  
  video {
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
  }

  video::after {
    height: 0px;
  }
}

#languageList {
  display: grid;
  grid-template-columns: 10em 1fr;
}

#contact-nav {
  display: flex;
}